<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.report.title') }}</h1>
                </div>
            </div>
            <TabsNav :items="breadcrumbs()[1].items" />

            <v-row>
                <v-col cols="6 ">
                    <BarChart
                        :options="chartOptions"
                        :chart-data="JSON.stringify(chartData)"
                    ></BarChart>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import WarehouseHeader from "@/components/Warehouse/WarehouseHeader.vue";
import Side from "@/components/Warehouse/Side.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";
import BarChart from "@/plugins/charts/BarChart";

export default {
    name: "Sale",

    components: {
        BarChart,
        TabsNav,
        Side,
        PageLayout
    },

    mixins: [NavMixin],

    data() {
        return {
            cData: {
                "labels": [],
                "datasets": [
                    {
                        "fill": true,
                        "label": this.$t('accounting.report.cashflow.income'),
                        "data": [],
                        "backgroundColor": [],
                        "borderWidth": 2
                    },
                    {
                        "fill": true,
                        "label": this.$t('accounting.report.cashflow.expense'),
                        "data": [],
                        "backgroundColor": [],
                        "borderWidth": 2
                    },
                    {
                        "type": 'line',
                        "label": this.$t('accounting.report.cashflow.saldo'),
                        "data": [],
                    }
                ]
            },
            opts: {}
        }
    },

    computed: {
        chartData() { return this.cData },
        chartOptions() { return this.opts }
    },

    mounted() {
        this.init();
    },


    methods: {
        init() {
            this.fetchCashFlow();
        },

        fetchCashFlow () {
            this.$http.get('admin/accounting/report/cashflow')
                .then(response => {
                    response.body.forEach((item) => {
                        this.cData.labels.push(item.month);
                        this.cData.datasets[0].data.push(parseFloat(item.inflow));
                        this.cData.datasets[0].backgroundColor.push("rgba(0, 255, 0, 0.5)");
                        this.cData.datasets[1].data.push(parseFloat(item.outflow));
                        this.cData.datasets[1].backgroundColor.push("rgba(255, 0, 0, 0.5)");
                        this.cData.datasets[2].data.push(parseFloat(item.net_flow));
                    });
                })
                .catch(error => {
                    this.$toastr.error(error.body.message);
                });
        }
    }
}
</script>
